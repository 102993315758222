import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import Link from 'gatsby-link'
import Header from '../components/header'





const AboutPage = ({data}) => {
  const intl = useIntl()
  return(

   
     <Layout>
       <Header
       title={intl.formatMessage({ id: "kontakt.Kontakt-UeberSchrift1" })}
       >
       </Header>
       <br/>
       <br/>
    <div className='row'>
      <div className='col-12'>
      <h3>{intl.formatMessage({ id: "kontakt.Kt-UeberUns" })} </h3>

      <p>
      {intl.formatMessage({ id: "kontakt.Kt-UeberUnsText1" })}  
      </p>
      <br/>
      <h4> {intl.formatMessage({ id: "kontakt.Kt-UeberUnsMaterialenwicklung" })} </h4>
      <p>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsMaterialenwicklungText1" })}
      </p>
      <p>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsMaterialenwicklungText2" })} </p>
      <p>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsMaterialenwicklungText3" })}</p>
      
      <br/>
      <h4>{intl.formatMessage({ id: "kontakt.Kt-UeberUns3DDruck" })}</h4>
      <p>{intl.formatMessage({ id: "kontakt.Kt-UeberUns3DDruckText1" })}
      </p>
      <br/>

      <h3> {intl.formatMessage({ id: "kontakt.Kt-UeberUnsKontakt" })}</h3>
          <p>
           {intl.formatMessage({ id: "kontakt.Kt-UeberUnsKontaktText1" })}
            <br/>
            {intl.formatMessage({ id: "global.IstFicotex" })}
            <br/>
            {intl.formatMessage({ id: "global.KompanieStrasse" })}
            <br/>
            {intl.formatMessage({ id: "global.Werlte" })}
          <br/>
          {intl.formatMessage({ id: "global.MailInfo" })}
          <br/>
          {intl.formatMessage({ id: "global.TelefonWerlte" })}
          <br/>
          {intl.formatMessage({ id: "global.Www" })}
      </p>
      </div>
    </div>
    <br/>


    <div className='row'>
      <div className='col-12'>
      <h3>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsImpressum" })}</h3>
      <p>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsImpressumText1" })}<Link to='/Impressum/impressum/'> {intl.formatMessage({ id: "global.Hier" })} </Link>. </p>
      </div>
    </div>

   <br/>
   
    <div className='row'>
      <div className='col-12'>
      <h3>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsDatenschutz" })}</h3>
      <p>{intl.formatMessage({ id: "kontakt.Kt-UeberUnsDatenschutzText1" })} <Link to='/Impressum/datenschutzbestimmungen/'> {intl.formatMessage({ id: "global.Hier" })} </Link>. </p>
      <br/>
      </div>

    </div>

  

    </Layout>
  )
   }

export const query = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
`

export default AboutPage;

